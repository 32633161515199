import Swiper from "swiper/bundle";
import Pristine from "pristinejs";

export default class AppLoader {
  constructor(appFactory, props) {
    const config = {
      ...{
        instanceName: "App",
        requiredFeatures: [
          Modernizr.strictmode,
          Modernizr.es5array,
          Modernizr.es5date,
          Modernizr.es5function,
          Modernizr.es5object,
          Modernizr.es5string,
          Modernizr.es5syntax,
          Modernizr.es5undefined,
          Modernizr.es6array,
          Modernizr.es6collections,
          Modernizr.es6math,
          Modernizr.es6number,
          Modernizr.es6object,
          // Modernizr.es6string,
          Modernizr.generators,
          // Modernizr.contains,
          Modernizr.promises,
        ],
        polyfillSrc: "assets/polyfill.min.js",
        appName: "Frontend Bundle Manager",
        appVersion: "v.1.0",
      },
      ...props,
    };

    document.addEventListener("DOMContentLoaded", () => {
      if (config.appName) {
        console.log(config.appName);
        console.log("==================");
      }

      const accordionButtons = [
        ...document.querySelectorAll(".dms-accordion-item button"),
      ];

      function onAccordionClicked(event) {
        const parentElem =
          event.target.parentElement.tagName === "DIV"
            ? event.target.parentElement
            : event.target.parentElement.parentElement;
        if (parentElem.classList.contains("dms-accordion-item--open")) {
          parentElem.classList.remove("dms-accordion-item--open");
          parentElem.querySelector("p").style = "height: 0";
          if (
            parentElem
              .querySelector(".icon__app")
              .classList.contains("icon__app--minus-primary")
          ) {
            parentElem
              .querySelector(".icon__app")
              .classList.remove("icon__app--minus-primary");
          }
          parentElem
            .querySelector(".icon__app")
            .classList.add("icon__app--plus-primary");
        } else {
          parentElem.classList.add("dms-accordion-item--open");
          parentElem.querySelector("p").style = `height: ${
            parentElem.querySelector("p").scrollHeight
          }px`;
          if (
            parentElem
              .querySelector(".icon__app")
              .classList.contains("icon__app--plus-primary")
          ) {
            parentElem
              .querySelector(".icon__app")
              .classList.remove("icon__app--plus-primary");
          }
          parentElem
            .querySelector(".icon__app")
            .classList.add("icon__app--minus-primary");
        }
      }

      accordionButtons.forEach((button) => {
        button.addEventListener("mousedown", onAccordionClicked);
        if (
          button.parentElement.classList.contains("dms-accordion-item--open")
        ) {
          const parentElem = button.parentElement;
          parentElem.querySelector("p").style = `height: ${
            parentElem.querySelector("p").scrollHeight
          }px`;
          if (
            parentElem
              .querySelector(".icon__app")
              .classList.contains("icon__app--plus-primary")
          ) {
            parentElem
              .querySelector(".icon__app")
              .classList.remove("icon__app--plus-primary");
          }
          if (
            parentElem
              .querySelector(".icon__app")
              .classList.contains("icon__app--minus-primary")
          ) {
            parentElem
              .querySelector(".icon__app")
              .classList.remove("icon__app--minus-primary");
          }
          parentElem
            .querySelector(".icon__app")
            .classList.add("icon__app--minus-primary");
        }
      });

      const aboutSwiper = new Swiper("#about-gallery-container", {
        centeredSlides: true,
        spaceBetween: 24,
        threshold: 10,
        loop: true,
        loopedSlides: 7,
        slidesPerView: "auto",
        pagination: {
          el: "#about-gallery-pagination",
          clickable: true,
        },
        autoplay: false,
      });

      const fullscreenSwiper = new Swiper("#fullscreen-gallery-container", {
        centeredSlides: true,
        threshold: 10,
        observer: true,
        observeParents: true,
        loop: true,
        loopedSlides: 6,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        autoplay: false,
      });

      const detailThumbs = new Swiper("#detail-gallery-thumbs", {
        spaceBetween: 8,
        centeredSlides: false,
        slidesPerView: "auto",
        slideToClickedSlide: false,
        threshold: 10,
      });

      const detailSwiper = new Swiper("#detail-gallery-container", {
        centeredSlides: false,
        threshold: 10,
        slidesPerView: 1,
        spaceBetween: 8,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        thumbs: {
          swiper: detailThumbs,
        },
        autoplay: false,
      });

      const galleryImages = [
        ...document
          .querySelector(".section-about__gallery")
          .querySelectorAll("img"),
      ];
      document
        .querySelector(".fullscreen-gallery")
        .addEventListener("click", (event) => {
          if (
            event.target.classList.contains("fullscreen-gallery") ||
            event.target.classList.contains("icon__app")
          ) {
            document.querySelector(".fullscreen-gallery").style =
              "display: none";
          }
        });

      const videos = [...document.querySelectorAll("#about-video")];
      videos.forEach((vid) => {
        vid.addEventListener("click", (ev) => {
          console.log("clicked video: ", ev.target);
          vid.setAttribute("controls", "controls");
        });
      });

      galleryImages.forEach((image, index) => {
        const galleryIndex = index % 6;
        image.addEventListener("click", (event) => {
          document.querySelector(".fullscreen-gallery").style = "display: flex";
          fullscreenSwiper.slideTo(galleryIndex);
        });
      });

      const header = document.querySelector(".c-header");
      const pIndex = document.querySelector(".p-index");

      document.body.addEventListener("scroll", () => {
        if (
          document.body.scrollTop >
          document.querySelector(".section-hero").getBoundingClientRect().height
        ) {
          if (!header.classList.contains("c-header--sticky")) {
            header.classList.add("c-header--sticky");
          }
          if (!pIndex.classList.contains("p-index--sticky")) {
            pIndex.classList.add("p-index--sticky");
          }
        } else {
          if (header.classList.contains("c-header--sticky")) {
            header.classList.remove("c-header--sticky");
          }
          if (pIndex.classList.contains("p-index--sticky")) {
            pIndex.classList.remove("p-index--sticky");
          }
        }
      });

      const form = document.getElementById("contact-form");

      if (form) {
        const pristineConfig = {
          // class of the parent element where the error/success class is added
          classTo: "c-form__input",
          errorClass: "c-form__input--error",
          successClass: "has-success",
          // class of the parent element where error text element is appended
          errorTextParent: "c-form__input",
          // type of element to create for the error text
          errorTextTag: "div",
          // class of the error text element
          errorTextClass: "c-form__input-error-text",
        };

        const pristine = new Pristine(form, pristineConfig, false);

        form.addEventListener("submit", function (e) {
          e.preventDefault();

          pristine.reset();
          pristine.validate();
          const errors = pristine.getErrors();
          if (!errors.length) {
            const formData = new FormData(this);
            const entries = formData.entries();
            const data = Object.fromEntries(entries);
            console.log(data);
            // Make API call with data or submit form with form.submit() here.
            // then and catch blocks example for showing corresponding messages:
            /*
              .then(() => {
                document.querySelector(".c-form__row--success").style =
                  "display: block;";
              })
              .catch(() => {
                document.querySelector(".c-form__row--error").style =
                  "display: block;";
              });
            */
          }
        });
      }

      if (config.appVersion) console.log(config.appVersion);
    });
  }

  loadScript(src, done) {
    var js = document.createElement("script");
    js.src = src;
    js.onload = function () {
      done();
    };
    js.onerror = function () {
      done(new Error("Failed to load script " + src));
    };
    document.head.appendChild(js);
  }
}
