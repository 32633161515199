import { DomComponent } from "./dom.component";

export default class BaseApp extends DomComponent {
  constructor(settings = null, app = null, element = null) {
    if (!element) element = document.querySelector("body");
    super(app, element);
    this.settings = settings;
    this.documentElement = window.document.documentElement;
    this._controller = {};
    this._controllers = [];
    _.templateSettings.interpolate = /{{([\s\S]+?)}}/g;
  }

  ready() {
    this.isReady = true;
    this.dispatchEvent(BaseApp.EVENT_APP_INIT);
  }

  createController(name, componentFactory, ...args) {
    this.setController(name, new componentFactory(this, ...args));
  }

  setController(name, controller) {
    this._controller[name] = controller;
    if (this._controllers.indexOf(this._controller[name]) < 0) {
      this._controllers.push(this._controller[name]);
    }
  }

  getController(name) {
    return this._controller[name];
  }

  stringToFunction(str) {
    var arr = str.split(".");

    var fn = window || this;
    for (var i = 0, len = arr.length; i < len; i++) {
      fn = fn[arr[i]];
    }

    if (typeof fn !== "function") {
      throw new Error("function not found");
    }

    return fn;
  }
}

BaseApp.EVENT_APP_INIT = "app-init";
