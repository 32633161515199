import { BaseController } from "../core/base.controller";
import { LoadingController } from "./loading.controller";
import classNames from "classnames";

export class LayoutController extends BaseController {
    constructor(app) {
        super(app);
        
        this.scollIsLocked = false;
        this.outsideClickProxy = this.outsideClickHandler.bind(this);

        this._lastScrollTop = 0;
        this._scrollDirection = 0;   
        this._clickCount = 0;
    }

    appOnInit(){
        if(super.appOnInit) super.appOnInit();
        this.mainContainer = this.app.domElement;
        this.loadingController = this.app.getController('loading');
        this.viewportController = this.app.getController('viewport');

        this.loadingController.addEventListener(LoadingController.EVENT_CHANGE, this.loadingChangeHandler.bind(this));
    }

    getScrollTop() {
        return window.scrollY || window.pageYOffset || document.body.scrollTop + (document.documentElement && document.documentElement.scrollTop || 0);
    }

    getScrollHeight() {
        return Math.max(document.body.scrollHeight, document.documentElement.scrollHeight, document.body.offsetHeight, document.documentElement.offsetHeight, document.body.clientHeight, document.documentElement.clientHeight);
    }

    getContentTop() {
        return this.header.contentElement.offsetTop + this.header.contentElement.offsetHeight;
    }

    getHeight() {
        return window.innerHeight;
    }

    _setScrollDirection(direction) {
        if(this._scrollDirection == direction) return;
        this._scrollDirection = direction;
        this.dispatchEvent(LayoutController.EVENT_SCROLL_DIRECTION_CHANGE);
    }
    getScrollDirection() { return this._scrollDirection }

    scrollHandler(event) {
        if(this.scollIsLocked) {
            window.scrollTo(0, this._lastScrollTop);
            return;
        }

        if(this._lastScrollTop < this.getScrollTop()) {
            this._setScrollDirection(1);
        }else{
            this._setScrollDirection(-1);
        }
        this._lastScrollTop = this.getScrollTop();
        if(this.scrollTopButton) this.app.setClass(classNames('l-main__scroll-top', {'l-main__scroll-top--active': this._lastScrollTop  > this.getHeight()}), this.scrollTopButton);
        this.dispatchEvent(LayoutController.EVENT_SCROLL);
    }

    showOverlay(name = 'content', lockScroll = true, hideScroll = true) {
        switch(name) {
            default:
            case 'content':
                this.app.findChild('.l-main__content').then( element => this.app.addClass('l-main__content--overlay', element));
            break;
        }
        if(lockScroll) this.lockScroll(hideScroll);
    }

    hideOverlay(name = 'content', unlockScroll = true) {
        switch(name) {
            case 'content':
                this.app.findChild('.l-main__content').then( element => this.app.removeClass('l-main__content--overlay', element));
            break;
        }
        if(unlockScroll) this.unlockScroll();
    }

    lockScroll(hideScroll = false) {
        if(!this.mainContainer) return;
        this.scollIsLocked = true;
        if(hideScroll) this.app.addClass('l-main--no-scroll', this.mainContainer);
    }

    unlockScroll() {
        if(!this.mainContainer) return;
        this.scollIsLocked = false;
        this.app.removeClass('l-main--no-scroll', this.mainContainer);
        window.scrollTo(0, this._lastScrollTop);
    }

    scrollTop() {
        if(!this.mainContainer) return;
        window.scrollTo(0,0);
        this.mainContainer.scrollTop = 0;
    }

    scrollTo(x,y) {
        window.scrollTo(x,y);
    }

    showLoading() {
        this.app.addClass('l-main--loading', this.mainContainer);
    }

    hideLoading() {
        this.app.removeClass('l-main--loading', this.mainContainer)
    }

    loadingChangeHandler() {
        this.loadingController.getLoadingStatus(LoadingController.LOADING_MAIN) ? this.showLoading() : this.hideLoading() ;
    }

    outsideClickHandler(e) {
        if(e && e.target == this.drawer.element) {
            if(this._clickCount > 0) {
                
            }
        }
    }
}

LayoutController.EVENT_SCROLL_DIRECTION_CHANGE = "scroll-direction-change";
LayoutController.EVENT_SCROLL = "scroll";