import { BaseController } from "../core/base.controller";

export class AppController extends BaseController {

    constructor(app) {
        super(app, {
            declarations: [
                
            ]
        });
    }

    appOnInit(){
        if(super.appOnInit) super.appOnInit();
        this.init();
    }

    onViewChange() {
        
    }

    init() {
        
    }
}