export default {
    settings: {
        breakpoints: {
            xs: {min: 0, max: 575, breakpointName: "extrasmall", className: "phone"},
            sm: {min: 576, max: 767, breakpointName: "small", className: "phone"},
            md: {min: 768, max: 991, breakpointName: "medium", className: "tablet"},
            lg: {min: 992, max: 1199, breakpointName: "large", className: "desktop"},
            xl: {min: 1200, max: Infinity, breakpointName: "extralarge", className: "wide-desktop"}
        },
        breakpointClasses: ["phone", "tablet", "desktop"],
        i18n: {
            defaultLocale: {
              code: "en",
              name: "English",
              culture: "en-US",
              country: "US",
              currency: 'USD'
            },
            availableLocales: [
              {
                code: "en",
                name: "English",
                culture: "en-US",
                country: "US",
                currency: 'USD',
                defaultFormats: {
                    number: {
                        currency: {
                            style   : 'currency',
                            currency: 'USD'
                        }
                    }
                },
              }
            ]
        }
    }
}