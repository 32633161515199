import { BaseController } from "../core/base.controller";

export class LoadingController extends BaseController {

    constructor(app) {
        super(app);
        this.activeLoadings = [];
    }

    appOnInit(){
        if(super.appOnInit) super.appOnInit();
    }

    showLoading(loadingName = LoadingController.LOADING_MAIN) {
        this.addLoading(loadingName);
    }

    hideLoading(loadingName = LoadingController.LOADING_MAIN) {
        this.removeLoading(loadingName);
    }

    getLoadingStatus(...loadingNames) {
        if (!this.activeLoadings) return false;
        if (!loadingNames || !loadingNames[0]) return true;
        if (Array.isArray(loadingNames[0])) loadingNames = loadingNames[0];
        let hasStatus= false;
        loadingNames.forEach(name => {
            let index = this.activeLoadings.indexOf(name);
            hasStatus = index >= 0;
            if (hasStatus) return true;
        })
        return hasStatus;
    }

    addLoading(loadingName) {
        if (!this.activeLoadings) this.activeLoadings = [];
        let index = this.activeLoadings.indexOf(loadingName);
        if (index < 0) {
            this.activeLoadings.push(loadingName);
            this.dispatchEvent(LoadingController.EVENT_CHANGE);
        }
    }

    removeLoading(loadingName) {
        if (!this.activeLoadings) return;
        let index = this.activeLoadings.indexOf(loadingName);
        if (index >= 0) {
            this.activeLoadings.splice(index, 1);
            setTimeout(()=> {
                this.dispatchEvent(LoadingController.EVENT_CHANGE);
            }, 0);
        }
    }

    removeAllLoadings() {
        this.activeLoadings = [];
        this.dispatchEvent(LoadingController.EVENT_CHANGE);
    }
}

LoadingController.EVENT_CHANGE = "change";
LoadingController.LOADING_MAIN = "main";
LoadingController.LOADING_API_REQUEST = "api-request";