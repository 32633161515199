import EventDispatcher from "./classes/EventDispatcher";
import BaseApp from "./base.app";

export class DomComponent extends EventDispatcher {
    constructor(app, element, componentClass) {
        super();
        this.app = app;
        this.domElement = element;
        this._baseDomElement = element.cloneNode(true);
        this._componentClass = componentClass;
        this.domParser = new DOMParser();
        if(this.domElement) {
            this.domElement.component = this;
            this._baseClass = this.domElement.className;
        }

        if(this.app){
            if(this.app.isReady) {
                this.appReadyHandler();
            }else{
                this.app.addEventListener(BaseApp.EVENT_APP_INIT, this.appReadyHandler.bind(this));
            }
        }
    }

    appReadyHandler() {
        if(this.appOnInit) this.appOnInit();
    }

    getComponentClass() {
        return this._componentClass;
    }

    getBaseDomElement() {
        return this._baseDomElement;
    }

    getSelector(suffix="", prefix=".") {
        return prefix + this._componentClass + suffix;
    }

    getBassClass() {
        return this._baseClass;
    }

    getAttributes(element) {
        if(!element) element = this.domElement;
        let attributes, nodeValue;
        if(element.attributes && element.attributes.length) {
            attributes = {};
            for(var i=0; i<element.attributes.length; i++) {
                try {
                    nodeValue = this.parseJSON(element.attributes[i].nodeValue);
                }catch(error) {
                    nodeValue = element.attributes[i].nodeValue;
                }
                attributes[_.camelCase(element.attributes[i].nodeName)] = nodeValue;
            }
        }
        return attributes;
    }
 
    querySelector(selector, element) {
        if(!element) element = this.domElement;
        if(!element) return null;
        return element.querySelector(selector);
    }

    querySelectorAll(selector, element) {
        if(!element) element = this.domElement;
        if(!element) return null;
        return element.querySelectorAll(selector);
    }

    findChild(selector, element) {
        if(!element) element = this.domElement;
        if(!element) return Promise.reject({message: element + " element not found"});
        return new Promise((resolve, reject) => {
            let targetElement = element.querySelector(selector);
            if(targetElement) {
                resolve(targetElement);
            }else{
                reject({message: selector + " selector not found"});
            }
        });
    }

    findChildren(selector, element) {
        if(!element) element = this.domElement;
        if(!element) return Promise.reject({message: element + " element not found"});
        return new Promise((resolve, reject) => {
            if(Array.isArray(selector)) selector = selector.join(',');
            let targetElements = element.querySelectorAll(selector);
            if(targetElements && targetElements.length > 0) {
                resolve(targetElements);
            }else{
                reject({message: selector + " selector not found"});
            }
        });
    }

    closest(selector, element) {
        if(!element) element = this.domElement;
        if(!element) return Promise.reject({message: element + " element not found"});
        return new Promise((resolve, reject) => {
            let targetElement = element.closest(selector);
            if(targetElement) {
                resolve(targetElement);
            }else{
                reject({message: selector + " selector not found"});
            }
        });
    }

    setClass(classList, element) {
        if(!element) element = this.domElement;
        if(!element) return null;
        if(classList) {
            if(!Array.isArray(classList)) classList = classList.split(' ');
            if(element.classList && Array.isArray(element.classList)) {
                if(element.classList.join(' ') == classList.join(' ')) return;
            }else{
                if(element.className == classList.join(' ')) return;
            }
        }
        if(element.classList) {
            while (element.classList.length > 0) {
                element.classList.remove(element.classList.item(0));
            }
        }
        element.className = "";
        classList.forEach( classItem => this.addClass(classItem, element));
    }

    addClass(className, element) {
        if(!element) element = this.domElement;
        if(!element) return null;
        if(element.classList) {
            let newClassList = Array.isArray(className) ? className : className.split(' ');
            newClassList.forEach( classItem => element.classList.add(classItem.replace(' ', '')) );
        }else{
            element.className += ' ' + className;
        }
    }

    removeClass(className, element) {
        if(!element) element = this.domElement;
        if(!element) return null;
        if(element.classList) {
            let existClassList = Array.isArray(className) ? className : className.split(' ');
            existClassList.forEach( classItem => element.classList.remove(classItem.replace(' ', '')));
        }else{
            element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        }
    }

    hasClass(className, element) {
        if(!element) element = this.domElement;
        if(!element) return null;
        if(element.classList) {
            return element.classList.contains(className);
        }else{
            return new RegExp('(^| )' + className + '( |$)', 'gi').test(element.className);
        }
    }

    toggleClass(className, element) {
        if(!element) element = this.domElement;
        if(!element) return null;
        if(element.classList) {
            element.classList.toggle(className);
        }else{
            let classes = element.className.split(' ');
            let existingIndex = classes.indexOf(className);
          
            if (existingIndex >= 0)
              classes.splice(existingIndex, 1);
            else
              classes.push(className);
          
            element.className = classes.join(' ');
        }
    }

    createDomComponent(app, tag, options, parentElement = "self", beforeElement = null) {
        let element = document.createElement(tag);
        for(var key in options) {
            element[key] = options[key];
        }
        let component = new DomComponent(app, element);
        
        if(parentElement === "self") parentElement = this.domElement;
        if(typeof parentElement === 'string') parentElement = document.querySelector(parentElement);
        if(parentElement) {
            parentElement.appendChild(component.domElement);
            if(beforeElement) {
                parentElement.insertBefore(component.domElement, beforeElement);
            }
        }
        return component;
    }

    html(data, element) {
        if(!element) element = this.domElement;
        if(!element) return null;
        if(data != null) {
            element.innerHTML = data;
        }else{
            return element.innerHTML;
        }
    }

    appendChild(newElement, element) {
        if(!element) element = this.domElement;
        if(!element) return null;
        if(typeof newElement === 'string') {
            newElement = new DOMParser().parseFromString( newElement , 'text/html').body.firstChild;
        }
        element.appendChild(newElement);
    }

    clone(element) {
        if(!element) element = this.domElement;
        if(!element) return null;
        return new DomComponent(this.app, element.cloneNode(true));
    }

    destroy(element) {
        if(!element) element = this.domElement;
        if(!element) return null;
        if(this.onDestroy) this.onDestroy();
        element.parentNode.removeChild(element);
        this.dispatchEvent(DomComponent.EVENT_REMOVED);
    }

    empty(element) {
        if(!element) element = this.domElement;
        if(!element) return null;
        if(this.onEmpty) this.onEmpty();
        this.html("", element)
        this.dispatchEvent(DomComponent.EVENT_EMPTY);
    }

    getJSON(attrName, element, useNativeJSON) {
        if(!element) element = this.domElement;
        if(!element) return null;
        let attr = element.getAttribute('json-'+attrName);
        if(attr) {
            return this.parseJSON(attr, useNativeJSON);
        }else{
            let scopeElement = element.querySelector('[json-scope="'+attrName+'"]');
            if(scopeElement) {
                attr = scopeElement.getAttribute('json-'+attrName);
                return this.parseJSON(attr, useNativeJSON);
            }else{
                return null;
            }
        }
    }

    parseJSON(str, useNativeJSON, removePlus) {
        if(!str) return null;
        if(removePlus) str = str.replace(/\+/g,  " ");
        let parsed;
        try {
            parsed = useNativeJSON ? JSON.parse(this.decodeURIComponent(str, removePlus)) : eval('('+this.decodeURIComponent(str, removePlus)+')');
        }catch(e) {
            try {
                parsed = JSON.parse(this.decodeURIComponent(str, removePlus));
            } catch(e) { 
                parsed = str;
            }
        } 
        return parsed;
    }

    render(template, data, element) {
        if(!element) element = this.domElement;
        if(!element) return null;
        this.html(_.template(template)(data), element);
    }

    trigger(event, params, element) {
        if(!element) element = this.domElement;
        if(!element) return null;

        if (window.CustomEvent) {
            var event = new CustomEvent('my-event', {detail: params});
        } else {
            var event = document.createEvent('CustomEvent');
            event.initCustomEvent('my-event', true, true, params);
        }
        element.dispatchEvent(event);
    }

    handleSelectorError(error) {
        if(this.app.environment.name != 'production' ) {
            console.log(error.message);
        }
    }

    decodeURIComponent(value, removePlus) {
        if(!value || !value.length) return null;
        if(removePlus) value = value.replace(/\+/g,  " ");
        if(removePlus) value = value.replace(/%2B/g,  "%20");
        return decodeURIComponent(value)
    }

    trigger(event, element) {
        if(!element) element = this.domElement;
        if(!element) return null;

        if (typeof(Event) === 'function') {
            element.dispatchEvent(new Event(event));
          } else {
            // for IE and other old browsers
            // causes deprecation warning on modern browsers
            var evt = window.document.createEvent('UIEvents'); 
            evt.initUIEvent(event, true, false, window, 0); 
            element.dispatchEvent(evt);
          }
    }
}

DomComponent.EVENT_REMOVED = "removed";
DomComponent.EVENT_EMPTY = "empty"
DomComponent.EVENT_INIT = "init";