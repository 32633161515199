import { BaseController } from "../core/base.controller";

export class ViewportController extends BaseController {

    constructor(app) {
        super(app);
        this.currentBreakpoint = null;
        this.currentViewport = null;
        this.currentViewportClass = null;

        this.isPhone = false;
        this.isTablet = false;
        this.isMobile = false;
        this.isDesktop = false;
        this.isWideDesktop = false;
    }

    appOnInit(){
        let self = this;
        Breakpoints.on('change', function(){
            self.setViewport(this.current, this.previous);
        });

        Breakpoints(this.app.settings.breakpoints);

        window.addEventListener('resize', this.resizeHandler.bind(this));

        setTimeout(()=> {
            this.setViewport(Breakpoints.current());
        }, 50);
    }

    breakpointChangeHandler() {
        this.setViewport(this.current, this.previous);
    }

    setViewport(currentBreakpoint, prevBreakpoint = null) {
        var currentClass = this.app.settings.breakpoints[currentBreakpoint.name].className;
        var prevClass = prevBreakpoint ? this.app.settings.breakpoints[prevBreakpoint.name].className : this.app.settings.breakpointClasses.join(" ");
        this.app.removeClass(prevClass);
        this.app.addClass(currentClass);
        
        this.currentBreakpoint = currentBreakpoint;
        this.currentViewportClass = currentClass;
        this.currentViewport = this.currentBreakpoint ? this.app.settings.breakpoints[this.currentBreakpoint.name] : null;

        if(this.currentViewport) {
            this.isPhone = this.currentViewport.className == 'phone';
            this.isTablet = this.currentViewport.className == 'tablet';
            this.isMobile = this.isPhone || this.isTablet;
            this.isDesktop = !this.isMobile;
            this.isWideDesktop = this.currentViewport.className == "wide-desktop";
        }

        this.dispatchEvent(ViewportController.EVENT_BREAKPOINT_CHANGE);
    }

    resizeHandler(event) {
        this.dispatchEvent({type: ViewportController.EVENT_RESIZE, originalEvent: event});
    }
}

ViewportController.EVENT_BREAKPOINT_CHANGE = 'breakpoint-change';
ViewportController.EVENT_RESIZE = 'resize';